<template>
	<div id="app">
		<test></test>
		<img src="./images/banner4@1x.png" style="width: 100%;" />
		<h1>商户贷</h1>
		<div class="cont">
			<h3>商户贷产品</h3>
			<img src="./images/zp_18.png" />
			<p>面向场景商户，基于场景数据进行授信的快速信贷产品</p>
			<ul>
				<li v-for="(item,index) in list.slice(0,1)" :key="index" v-on:mouseover="changeActive(index,$event)" v-on:mouseout="removeActive(index)">
					<img src="./images/img.png" alt="">
					<div class="bg"></div>
					<div class="text" v-if="index==0" v-show="show">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==0" v-show="hide">
						<img :src="item.contentImg">
						<p>扫码了解更多</p>
					</div>
					<!-- <div class="text" v-if="index==1" v-show="show2">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==1" v-show="hide2">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div>
					<div class="text" v-if="index==2" v-show="show3">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==2" v-show="hide3">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div>
					<div class="text" v-if="index==3" v-show="show4">
						<h3>{{item.contentTitle}}</h3>
						<p>最高可借额度(元)</p>
						<h1>{{item.contentDescription}}</h1>
					</div>
					<div class="centers text" v-if="index==3" v-show="hide4">
						<img :src="item.contentImg">
						<p>请扫描二维码，并关注</p>
					</div> -->
					<div class="txt">
						<h4>{{item.contentTitle}}</h4>
						<p  v-html='item.contentDetails'></p>
						<!-- <button v-if="index==0" v-show="show">在线申请</button>
						<button v-if="index==0" class="on" v-show="hide" >在线申请</button> -->
						<!-- <button v-if="index==1" v-show="show2">在线申请</button>
						<button v-if="index==1" class="on" v-show="hide2" @click="build()">在线申请</button>
						<button v-if="index==2" v-show="show3">在线申请</button>
						<button v-if="index==2" class="on" v-show="hide3" @click="build()">在线申请</button>
						<button v-if="index==3" v-show="show4">在线申请</button>
						<button v-if="index==3" class="on" v-show="hide4" @click="build()">在线申请</button> -->
					</div>
				</li>
			</ul>
			<h3>产品优势</h3>
			<img src="./images/zp_18.png" />
			<div class="ul">
				<div class="li">
					<img src="./images/images/icons_03.png" />
					<h3>放款快</h3>
					<p>白名单，提前授信</p>
				</div>
				<div class="li">
					<img src="./images/images/icons_05.png" />
					<h3>门槛低</h3>
					<p>全线上申请<br>所需资料少</p>
				</div>
				<div class="li">
					<img src="./images/images/icons_07.png" />
					<h3>周期长</h3>
					<p>可分12期还款</p>
				</div>
				<div class="li">
					<img src="./images/images/icons_09.png" />
					<h3>额度高</h3>
					<p>额度最高可达100万</p>
				</div>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	
	components: {
		test,
		foot	
	},
  data() {
	return {
	  inActive: false,
	  list:[],
	  show:true,
	  hide:false,
	  show2:true,
	  hide2:false,
	  show3:true,
	  hide3:false,
	  show4:true,
	  hide4:false,
	}
  },
  created(){
	if (location.href.indexOf("#reloaded") == -1) {
	  location.href = location.href + "#reloaded";
	  location.reload();
	} 
	var that = this
	this.$axios.post('/cms/returnAll/officialWebsite')
	.then(function (res) {
		console.log(res);
		 that.list = res.data.categoryList[1].contentList
		 
	})
	
  
  },
  methods: {
	 // build(){
	 // 		  var that = this;
	 // 		  that.$router.push('/build');
	 // }, 
	changeActive(index,$event){
			 console.log($event)
			 if(index==0){
				 this.hide = true
				 this.show = false
			 }
			 if(index==1){
				 this.hide2 = true
				 this.show2 = false
			 }
			 if(index==2){
				 this.hide3 = true
				 this.show3 = false
			 }
			 if(index==3){
				 this.hide4 = true
				 this.show4 = false
			 }
			 
	    // $event.currentTarget.className="on";
	 },
	 removeActive(index){
			  if(index==0){
				  this.hide = false
				  this.show = true
			  }
			  if(index==1){
				  this.hide2 = false
				  this.show2 = true
			  }
			  if(index==2){
				  this.hide3 = false
				  this.show3 = true
			  }
			   if(index==3){
				  this.hide4 = false
				  this.show4 = true
			   }
	 }
  },


}
</script>

<style>
	button{
		outline: none;
	}
	.cont ul li .txt .on{
		background-color: #ea0029;
		color: #fff;
	}
	.cont ul li .centers{
		text-align: center !important;
		width: 86%;
	}
	.cont ul li .centers>img{
		width: 150px;
		height: auto;
	}
	#app>h1{
		font-size: 40px;
		letter-spacing:5px;
		font-weight: 300;
		position: absolute;
		top:30%;
		left: 50%;
		transform: translateX(-50%);
		color: #fff;
	}
	.ul{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 80px;
	}
	.ul .li{
		flex: 2;
		margin: 2%;
		
	}
	.ul .li h3{
		color: #3B3B3B;
	}
	.ul .li img{
		width: 50%;
		margin-bottom: 10px;
	}
	.cont>img{
		width: 50px;
	}
	.cont ul li .txt{
		margin-top: 350px;
		text-align: left;
		/* height: 130px; */
		border:1px solid #f4f6f8;
		padding:4%;				
	}
	.cont ul li .txt button{
		margin-top: 20px;
		font-size: 14px;
		border: 1px solid #ea0029;
		background-color: #fff;
		border-radius: 30px;
		color: #ea0029;
		padding: 13px 25px;
		position: absolute;
		bottom: 20px;
		width: 91%;
	}
	.cont ul li .txt h4{
		font-weight: normal;
		font-size: 18px;
		color: #3B3B3B;
	}
	.cont ul li .text{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		color: #fff;
		padding: 7%;
		text-align: left;
	}
	.cont ul li .text p{
		margin-top: 20px;
		color: #fff;
	}
	.cont ul li .text h3{
		font-weight: normal;
		color: #fff;
		margin-bottom: 20px;
	}
	.cont ul li .text h1{
		margin-top: 20px;
	}
	.cont{
		padding: 40px 0;
		width: 65%;
		margin: 0 auto;
		background-color: #fff;
	}
	.cont h3{
		margin: 0;
		color: #333
	}
	.cont p{
		font-size: 12px;
		color: #666;
		margin-top: 10px;
	}
	.cont ul{
		margin-bottom: 60px;
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}
	.cont ul li{
		position: relative;
		width: 75%;
		margin: 1%;
	}
	.cont ul li>img{
		width: 100%;
		height:350px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 8;
	}
	.cont ul li .bg{
		width:100%;
		height: 350px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	.cont ul li:first-child .bg{
		background:rgba(0,182,68,0.5);
	}
	.cont ul li:nth-child(2) .bg{
		background:rgba(138,53,6,0.5);
	}
	.cont ul li:nth-child(3) .bg{
		background:rgba(255,62,0,0.5);
	}
	.cont ul li:nth-child(4) .bg{
		background:rgba(0,29,254,0.5);
	}
</style>
